import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from './itemProp/BlogProp';
import HeaderBlog from '../../common/header/HeaderBlog';
import FooterFour from '../../common/footer/FooterFour';

const BlogGridView = () => {
    return (
        <>
            <SEO title="Iotina Blogs" />
            <div>
               <HeaderBlog btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                <div className="main-content pt--100">
                    {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f2f2f2', padding: 20}}>
                        <div style={{fontSize: 25}}><b>Bringing you the latest technology, networking and intelligence Insights.</b></div>
                    </div> */}

                    {/* <div className="slider-area slider-style-1 height-custom-30 bg_image" data-black-overlay="15" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-05.jpg)`}}> */}
                    <div className="slider-area slider-style-1 height-custom-30 bg_image" data-black-overlay="15" style={{backgroundImage: `url(/images/bg/bg-05.jpg)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 pt--80" style={{color: '#d7d7d7', fontSize: 28, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
                                    Bringing you the latest technology, networking and intelligence Insights.
                                </div>
                    
                            </div>
                        </div>
                    </div>
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
            </div>
            <FooterFour />
        </>
    )
}
export default BlogGridView;